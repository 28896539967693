<template>
<ion-content class="ion-padding" :fullscreen="true">
  <div class="container">
    <h3>Konfirmasi Pembelian Produk</h3>
    <div class="row">
      <div class="col">
        Doo.id Poin
      </div>
      <div class="col">
        {{ $filters.numFormat($auth.point) }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        Harga
      </div>
      <div class="col">{{ $filters.numFormat(product.price) }}</div>
    </div>
    <div class="divinder"></div>
  </div>
</ion-content>
<ion-footer class="ion-no-border container split">
  <ion-toolbar>
    <div class="flex justify-content-between">
      <ion-button mode="ios" shape="round" expand="full" color="transparent" class="btn transparent" @click="close">Batal</ion-button>
      <ion-button mode="ios" shape="round" expand="full" color="dark" class="btn"  @click="buy" :disabled="disabled">Konfirmasi</ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
</template>

<script>
import { IonContent, IonFooter, IonToolbar, IonButton, modalController  } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Confirm from '@/components/confirm'

export default defineComponent({
  components: {
    IonContent, IonFooter, IonToolbar, IonButton
  },
  props: {
    product: Object,
    data: Object
  },
  data () {
    return {
      disabled: false
    }
  },
  methods: {
    async close() {
      await modalController.dismiss()
    },
    async buy () {
      let modal = await modalController
        .create({
          id: 'modal-buy',
          component: Confirm,
          swipeToClose: true,
          // cssClass: 'half-modal',
          componentProps: {
            data: this.data,
            redirect: '/store/transaction',
            url: 'api/store/product/buy'
          }
        })
      // modalController.dismiss()
      this.$store.commit('setModal', modal);
      return modal.present()
      // try {
      //   await this.$api.post('api/store/product/buy', this.data)
      //   modalController.dismiss()
      //   this.$store.dispatch('auth/fetchUser')
      //   this.$router.push({name: 'StoreTransaction'})
      // } catch (err) {
      //   let toast = await toastController.create({
      //       message: err.response.data.message,
      //       color: 'danger',
      //       duration: 2000,
      //       mode: 'md'
      //     })
      //   return toast.present();
      // }
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
})
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
}

.btn {
  width: 100%;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &.transparent {
    box-shadow: none;
    color: #000;
    &.button-round {
      --box-shadow: none !important;
    }
  }
}
</style>
