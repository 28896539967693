<template>
  <ion-page>
    <Header :title="product.name" v-if="product" />
    <ion-content :fullscreen="true">
      <div class="container">
        <div class="card-coupon" v-if="product">
          <div class="left-image">
            <ion-avatar>
              <img :src="product.image" :alt="product.name">
            </ion-avatar>
          </div>
          <div class="item-description">
            <div class="t">{{ product.name }}</div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M7.98625 15.9725C12.3969 15.9725 15.9725 12.3969 15.9725 7.98625C15.9725 3.57557 12.3969 0 7.98625 0C3.57557 0 0 3.57557 0 7.98625C0 12.3969 3.57557 15.9725 7.98625 15.9725Z" fill="#FFBC51"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.98647 1.62635C8.26345 1.62635 8.53633 1.644 8.80399 1.6784C9.04838 1.70975 9.27228 1.53686 9.30369 1.29248C9.3351 1.04809 9.16219 0.824193 8.9178 0.792812C8.61285 0.753608 8.30199 0.733398 7.98647 0.733398C7.74008 0.733398 7.54004 0.933435 7.54004 1.17983C7.54004 1.42637 7.74008 1.62635 7.98647 1.62635Z" fill="#FCD28A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.91554 1.92467C11.4874 2.42507 12.7947 3.52083 13.5727 4.94592C13.6908 5.16233 13.9622 5.24208 14.1785 5.12398C14.3947 5.00588 14.4745 4.73438 14.3564 4.51827C13.4694 2.8932 11.9785 1.64465 10.1865 1.07388C9.9517 0.999095 9.70041 1.129 9.62562 1.36371C9.55087 1.59869 9.68078 1.8498 9.91554 1.92467Z" fill="#FCD28A"/>
              <path d="M7.98587 13.723C11.1542 13.723 13.7227 11.1545 13.7227 7.98611C13.7227 4.81774 11.1542 2.24927 7.98587 2.24927C4.8175 2.24927 2.24902 4.81774 2.24902 7.98611C2.24902 11.1545 4.8175 13.723 7.98587 13.723Z" fill="#FFB32C"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.36477 13.0896C3.5151 12.1371 2.24902 10.2082 2.24902 7.98605C2.24902 4.81998 4.81963 2.24927 7.98588 2.24927C9.3917 2.24927 10.6801 2.75605 11.6784 3.59712C10.8927 3.19212 10.0015 2.96355 9.05731 2.96355C5.89106 2.96355 3.32045 5.53427 3.32045 8.70034C3.32045 10.4611 4.11513 12.0371 5.36477 13.0896Z" fill="#F49E17"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54305 4.76439C7.62309 4.59182 7.79593 4.48145 7.98606 4.48145C8.17616 4.48145 8.349 4.59182 8.42905 4.76439L9.25908 6.55236L11.2162 6.78932C11.4049 6.81208 11.5633 6.9423 11.622 7.12334C11.6807 7.30415 11.6292 7.50262 11.49 7.63187L10.0459 8.974L10.4254 10.9084C10.462 11.095 10.387 11.2858 10.2332 11.3976C10.0794 11.5094 9.87472 11.5217 9.70858 11.429L7.98606 10.4706L6.26351 11.429C6.0974 11.5217 5.89273 11.5094 5.73891 11.3976C5.58511 11.2858 5.51013 11.095 5.54673 10.9084L5.92623 8.974L4.48213 7.63187C4.34285 7.50262 4.29135 7.30415 4.35009 7.12334C4.40886 6.9423 4.56718 6.81208 4.75592 6.78932L6.71301 6.55236L7.54305 4.76439Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.4412 6.7063C6.18163 8.77166 8.07409 10.2906 10.3427 10.487L10.4254 10.9084C10.462 11.0949 10.387 11.2856 10.2332 11.3974C10.0794 11.5092 9.8747 11.5217 9.70859 11.4292L7.98605 10.4702L6.26352 11.4292C6.09737 11.5217 5.89273 11.5092 5.73891 11.3974C5.58509 11.2856 5.51012 11.0949 5.54673 10.9084L5.92623 8.9738L4.48213 7.63201C4.34288 7.50237 4.29134 7.30416 4.35009 7.12309C4.40884 6.94237 4.5672 6.81202 4.75591 6.78916L5.4412 6.7063Z" fill="#E9F2FE"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="16" height="16" fill="white"/>
              </clipPath>
              </defs>
            </svg> {{ $filters.numFormat(product.price) }} poin
          </div>
        </div>

        <div class="sx mt-2">
          <div class="bold">Alamat Pengiriman</div>
          <div class="text-sm">Isi data diri dengan benar untuk pengiriman produk.</div>
        </div>
        <div class="form mt-1">
          <label for="phone">No. Handphone</label>
          <ion-input type="text" class="input" placeholder="08xxxxxxx" v-model="$auth.phone" :readonly="$auth.phone_verified"></ion-input>

          <div class="flex justify-content-between">
            <label for="name">Alamat Pengirim</label>
            <a class="clone text-sm" @click="clone">Gunakan alamat utama </a>
          </div>
          <ion-textarea v-model="address" class="input"></ion-textarea>

          <label for="phone">Kota/Kabupaten</label>
          <ion-input type="text" class="input" v-model="city"></ion-input>
          <!-- <ion-select interface="action-sheet" v-model="city" ok-text="OK" cancel-text="Cancel">
            <ion-select-option :value="c.name" v-for="c in cities" :key="c.id">
              {{ c.name }}
            </ion-select-option>
          </ion-select> -->

          <label for="phone">Kode Pos</label>
          <ion-input type="text" class="input" v-model="postal_code"></ion-input>

          <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="buy">Selanjutnya</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonInput,
  IonTextarea,
  IonButton,
  IonAvatar,
  toastController,
  modalController
} from '@ionic/vue';
import Header from '@/components/headerPage';
import { mapGetters } from 'vuex'
import ModalConfirm from '@/views/store/buy-confirm'

export default {
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonTextarea,
    IonButton,
    IonAvatar,
    Header
  },
  data() {
    return {
      product: null,
      address: null,
      city: null,
      postal_code: null,
    }
  },
  created () {
    this.getProduct();
  },
  watch: {
    '$route': 'getProduct'
  },
  methods: {
    async getProduct() {
      let id = this.$route.params.id
      if(id)
        this.product = await this.$api.get(`/api/store/product/${id}`).then(res => res.data)
    },
    clone() {
      if(! this.$auth.address){
        toastController
          .create({
            message: 'Tidak ada alamat utama',
            duration: 2000,
            color: 'danger',
          }).then(r => r.present())
      }else{
        let address = this.$auth.address
        this.address = address.address
        this.city = address.city
        this.postal_code = address.postal_code
        this.phone = this.$auth.phone
      }
    },
    async buy () {
      if(this.$auth.phone == null || this.address == null || this.city == null || this.postal_code == null || this.phone == ''){
        toastController
          .create({
            message: 'Pastikan data terisi dengan benar.',
            duration: 2000,
            color: 'danger',
          }).then(r => r.present())
          return;
      }

      if(this.$auth.point < this.product.price )
      {
        toastController
          .create({
            message: 'Poin anda kurang.',
            duration: 2000,
            color: 'danger',
          }).then(r => r.present())
        return;
      }

      let data = {
        address: this.address,
        city: this.city,
        postal_code: this.postal_code,
        phone: this.$auth.phone,
        product_id: this.product.id
      }

      let modal = await modalController
        .create({
          component: ModalConfirm,
          swipeToClose: true,
          cssClass: 'half-modal',
          componentProps: {
            data: data,
            product: this.product
          }
        })
      this.$store.commit('setModal', modal);
      return modal.present()
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
}
</script>

<style lang="scss">

</style>
